export const BOOKING_EXPIRE_MINUTES = 15

export const LOCAL_STORAGE_KEYS = {
  UNFINISHED_BOOKING: 'UNFINISHED_BOOKING',
  BOOKING_UPDATE: (id: string) => `BOOKING_UPDATE_${id}`,
}

export const PAGE_NAMES = {
  HOMEPAGE: 'homepage',
  RESULTS: 'results',
  CHECKOUT: 'checkout',
  PURCHASE: 'purchase',
  REFUND: 'refund',
}
