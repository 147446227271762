import { CountryCode } from 'interfaces/Country'

const prefixes = {
  ru: [
    900, 901, 902, 903, 904, 905, 906, 908, 909, 910, 911, 912, 913, 914, 915, 916, 917, 918, 919,
    920, 921, 922, 923, 924, 925, 926, 927, 928, 929, 930, 931, 932, 933, 934, 936, 937, 938, 939,
    941, 950, 951, 952, 953, 954, 955, 956, 958, 960, 961, 962, 963, 964, 965, 966, 967, 968, 969,
    970, 971, 977, 978, 980, 981, 982, 983, 984, 985, 986, 987, 988, 989, 991, 992, 993, 994, 995,
    996, 997, 999,
  ],
  uz: [590, 591, 593, 594, 595, 597, 598, 592],
  by: [24, 25, 29, 33, 44],
}

export const settings = {
  country: CountryCode.KZ,
  countries: [
    CountryCode.KZ,
    // CountryCode.RU,
    CountryCode.UZ,
    CountryCode.KG,
    CountryCode.TM,
    CountryCode.BY,
  ],
  masks: {
    ru: '... ... ....',
    kz: '... ... ....',
    uz: '... ... ....',
    kg: '... ......',
    tm: '... ......',
    by: '.. ... ....',
  },
  expressions: [
    {
      country: CountryCode.KZ,
      regexp: new RegExp(/^(7)7[04567]\d\d{3}\d{4}$/),
    },
    {
      country: CountryCode.KG,
      regexp: new RegExp(/^(996)\d{3}\d{6}$/),
    },
    {
      country: CountryCode.TM,
      regexp: new RegExp(/^(993)\d{3}\d{6}$/),
    },
    {
      country: CountryCode.RU,
      regexp: new RegExp(`^(7)(${prefixes.ru.join('|')})\\d{3}\\d{4}$`),
    },
    {
      country: CountryCode.UZ,
      regexp: new RegExp(`^(998)(${prefixes.uz.join('|')})\\d{3}\\d{4}$`),
    },
    {
      country: CountryCode.BY,
      regexp: new RegExp(`^(375)(${prefixes.by.join('|')})\\d{3}\\d{4}$`),
    },
  ],
}
