import 'react-phone-input-2/lib/style.css'

import { authEvent, AuthStatus } from 'analytics/events/auth'
import { sendOTP } from 'api/requests/sendOTP'
import { Box } from 'components/ui/Box'
import { Typography } from 'components/ui/Typography'
import { FormikHelpers } from 'formik'
import { AuthFormValues, AuthMethod, AuthPurpose } from 'interfaces/Auth'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { FC, useEffect, useRef, useState } from 'react'
import PhoneInput, { CountryData } from 'react-phone-input-2'
import ru from 'react-phone-input-2/lang/ru.json'
import { useToggle } from 'react-use'
import { settings } from 'schemas/phones'
import { useTheme } from 'styled-components'

import { useForm } from './hooks/useForm'
import {
  FlexInputsWrapper,
  StyledButton,
  StyledFormWrapper,
  UserDataInputWrapper,
  Wrapper,
} from './styles'

interface Props {
  type: AuthPurpose
}

export const SignInForm: FC<Props> = ({ type: purpose }) => {
  const theme = useTheme()
  const router = useRouter()
  const { t } = useTranslation()
  const [phoneWidth, setWidth] = useState(0)

  const [isFormSubmitting, toggleFormSubmitting] = useToggle(false)

  const phoneRef = useRef<HTMLInputElement>(null)

  const handleFormSubmit = async (
    values: AuthFormValues,
    { setErrors }: FormikHelpers<AuthFormValues>
  ) => {
    try {
      toggleFormSubmitting(true)
      await sendOTP(values)
      await router.push({
        pathname: 'otp/[type]',
        query: {
          type: purpose,
          method: AuthMethod.PHONE,
          countryCode: values.phone.countryCode,
          dialCode: `+${String(values.phone.dialCode)}`,
          value: phoneRef?.current?.value,
        },
      })
    } catch (e: unknown) {
      setErrors({
        phone: {
          value: t(`returnPrintForm:phone.apiError`),
        },
      })
      toggleFormSubmitting(false)
      /* Analytics event */
      authEvent(values.type, AuthStatus.Failure)
    }
  }

  const formik = useForm({}, handleFormSubmit)

  const handleChange = (value: string, { countryCode, dialCode }: CountryData) => {
    const phone = { value, countryCode, dialCode }
    void formik.setFieldValue('phone', phone)
    void formik.setFieldTouched('phone', true)
  }

  const handleBlur = () => {
    void formik.setFieldTouched('phone', true)
  }

  useEffect(() => {
    if (!phoneRef.current) return

    const resizeObserver = new ResizeObserver(() => {
      setWidth(phoneRef.current?.parentElement?.offsetWidth || 0)
    })

    resizeObserver.observe(phoneRef.current)

    return () => resizeObserver.disconnect()
  }, [])

  return (
    <Wrapper phoneWidth={phoneWidth}>
      <StyledFormWrapper onSubmit={formik.handleSubmit}>
        <FlexInputsWrapper container alignItems='flex-start'>
          <UserDataInputWrapper>
            <PhoneInput
              localization={ru}
              country={settings.country}
              onlyCountries={settings.countries}
              masks={settings.masks}
              value={formik.values.phone.value}
              onChange={handleChange}
              onBlur={handleBlur}
              isValid={Boolean(!formik.errors.phone)}
              countryCodeEditable={false}
              disableCountryGuess={true}
              disableDropdown={true}
              inputProps={{ ref: phoneRef }}
              containerClass='phone__container'
              inputClass='phone__form-control'
              buttonClass='phone__flag-dropdown'
              dropdownClass='phone__country-list'
            />
            <Box marginTop={8}>
              <Typography
                color={
                  formik.errors?.phone?.value
                    ? theme.palette.secondary.MAIN
                    : theme.palette.core.DEEP_GRAY
                }
              >
                {formik.errors.phone?.value || t(`returnPrintForm:${formik.values.type}.hint`)}
              </Typography>
            </Box>
          </UserDataInputWrapper>
        </FlexInputsWrapper>
        <StyledButton
          tabIndex={0}
          type='submit'
          data-testid='submit-btn'
          disabled={!formik.isValid}
          isLoading={isFormSubmitting}
        >
          {t('core:continue')}
        </StyledButton>
      </StyledFormWrapper>
    </Wrapper>
  )
}
