import { Box } from 'components/ui/Box'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import { FC } from 'react'

import { useFormVariant } from '../../hooks/useFormVariant'
import { FormSelectorFlex, Separator, StyledLink, Wrapper } from './styles'

export const FormSelector: FC = () => {
  const { t } = useTranslation('home')
  const formVariant = useFormVariant()

  return (
    <Wrapper>
      <Box marginBottom={24}>
        <FormSelectorFlex container alignItems='center'>
          <Link href='/' passHref>
            <StyledLink selected={formVariant === 'search'} data-title={t('buyTicket')}>
              {t('buyTicket')}
            </StyledLink>
          </Link>
          <Separator />
          <Link href='/?refund' passHref>
            <StyledLink selected={formVariant === 'refund'} data-title={t('returnTicket')}>
              {t('returnTicket')}
            </StyledLink>
          </Link>
          <Separator />
          <Link href='/?print' passHref>
            <StyledLink selected={formVariant === 'print'} data-title={t('ticketPrint')}>
              {t('myTickets')}
            </StyledLink>
          </Link>
        </FormSelectorFlex>
      </Box>
    </Wrapper>
  )
}
