/* eslint-disable */
import * as Yup from 'yup'
import { settings } from 'schemas/phones'
import { ValidationError, CreateErrorOptions } from 'yup'

export function getRegularExpression(countryCode: string) {
  return settings.expressions.find(({ country })  => country === countryCode)
}

export function validate(
  value: any,
  ctx: any,
  message: string,
  createError: (params?: CreateErrorOptions) => ValidationError
) {
  const { countryCode } = ctx.parent
  const expression = getRegularExpression(countryCode)
  const isValid = expression?.regexp.test(value)

  if(!isValid) return createError({ path: ctx.path, message })

  return true
}

export function validatePhone(this: any, message = 'not valid') {
  return this.test('phone', function(this: any, value: any, ctx: any) {
    const { createError } = this

    return validate(value, ctx, message, createError)
  })
}

