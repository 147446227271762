import { pageLoadEvent } from 'analytics/events/pageLoad'
import { MetaHead } from 'components/shared/MetaHead'
import { PopularRoutes } from 'components/shared/PopularRoutes'
import { SearchTicketsForm } from 'components/shared/SearchTicketsForm'
import { SignInForm } from 'components/shared/SignInForm'
import { UnfinishedBooking } from 'components/shared/UnfinishedBooking'
import { Box } from 'components/ui/Box'
import { Container } from 'components/ui/Container'
import { Paper } from 'components/ui/Paper'
import { Typography } from 'components/ui/Typography'
import { PAGE_NAMES } from 'core/constants'
import { AuthPurpose } from 'interfaces/Auth'
import type { NextPage } from 'next'
import Image from 'next/image'
import Link from 'next/link'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'
import { useEffect } from 'react'
import { useTheme } from 'styled-components'

import { useFormVariant } from './hooks/useFormVariant'
import { FormSelector } from './libs/FormSelector'
// import { News } from './libs/News'
import {
  Background,
  BlockWrapper,
  Bold,
  FormWrapper,
  List,
  MainScreen,
  PaperCard,
  SeoContent,
  StyledFlex,
  TextContent,
  TitleTypographyWrapper,
} from './styles'

export const HomePage: NextPage = () => {
  const theme = useTheme()
  const { t, lang } = useTranslation()
  const formVariant = useFormVariant()

  useEffect(() => {
    /* Analytics event */
    pageLoadEvent(PAGE_NAMES.HOMEPAGE, lang)
  }, [])

  const howToSteps = Array.from(t('home:howTo.steps', {}, { returnObjects: true }))

  return (
    <>
      <MetaHead page='home' />
      <MainScreen>
        <Background>
          <Image
            src='/bg.jpeg'
            alt='Онлайн сервис по покупке ж/д билетов Актобе - Мангистау, Актобе - Астана | bile-bile.kz'
            title='Онлайн сервис по покупке ж/д билетов Актобе - Мангистау, Актобе - Астана'
            layout='fill'
            loading='eager'
            objectFit='cover'
          />
        </Background>
        <Container height='100%'>
          <StyledFlex container direction='column' justify='center' height='100%'>
            <Box marginBottom={24}>
              <TitleTypographyWrapper>
                <Typography
                  color={theme.palette.text.ALTERNATIVE}
                  as='h1'
                  typography={theme.typography.variants.title.H1}
                >
                  {t('home:title')}
                </Typography>
              </TitleTypographyWrapper>
            </Box>
            <FormSelector />
            <FormWrapper>
              <Paper
                background={theme.palette.core.WHITE}
                radius={12}
                shadow
                style={{ height: 'auto' }}
              >
                <Box padding={16}>
                  {formVariant === 'search' && <SearchTicketsForm />}
                  {formVariant === 'print' && <SignInForm type={AuthPurpose.PRINT} />}
                  {formVariant === 'refund' && <SignInForm type={AuthPurpose.REFUND} />}
                </Box>
              </Paper>
            </FormWrapper>
            {/* <News /> */}
          </StyledFlex>
        </Container>
      </MainScreen>
      <BlockWrapper bg={theme.palette.primary.MAIN}>
        <Container>
          <PopularRoutes />
        </Container>
      </BlockWrapper>
      <Container>
        <Box paddingTop={40} paddingBottom={40}>
          <SeoContent>
            <Box>
              <Box>
                <Typography typography={theme.typography.variants.title.H1}>
                  {t('home:about.title')}
                </Typography>
                <TextContent marginTop={8}>
                  <Typography typography={theme.typography.variants.content.MAIN}>
                    <Trans i18nKey='home:about.content' components={{ 0: <Bold /> }} />
                  </Typography>
                </TextContent>
              </Box>
              <Box marginTop={25}>
                <Typography typography={theme.typography.variants.title.H3}>
                  {t('home:howTo.title')}
                </Typography>
                <TextContent marginTop={8}>
                  <Typography typography={theme.typography.variants.content.MAIN}>
                    {t('home:howTo.content')}
                  </Typography>
                </TextContent>
                <TextContent marginTop={8}>
                  <List decimal>
                    {howToSteps.map((step, idx) => (
                      <li data-index={`${idx + 1}.`} key={step as string}>
                        <Typography>{step as string}</Typography>
                      </li>
                    ))}
                  </List>
                </TextContent>
              </Box>
            </Box>
            <Box>
              <PaperCard>
                <Typography typography={theme.typography.variants.title.H3}>
                  {t('home:direction.title')}
                </Typography>
                <Box marginTop={8}>
                  <Typography
                    typography={theme.typography.variants.content.MAIN}
                    color={theme.palette.core.DEEP_GRAY}
                  >
                    {t('home:direction.content')}
                  </Typography>
                </Box>
                <Box marginTop={16}>
                  <List>
                    <li>
                      <Typography typography={theme.typography.variants.content.BOLD}>
                        <Link passHref href='/aktob-mangs'>
                          <a>
                            <Trans
                              i18nKey={'home:direction.directionAktobMangs'}
                              components={{ 0: <br /> }}
                            />
                          </a>
                        </Link>
                      </Typography>
                    </li>
                  </List>
                </Box>
              </PaperCard>
            </Box>
          </SeoContent>
        </Box>
      </Container>
      <UnfinishedBooking />
    </>
  )
}
