import { FormikHelpers, useFormik } from 'formik'
import { AuthFormValues, AuthMethod } from 'interfaces/Auth'
import useTranslation from 'next-translate/useTranslation'
import { validatePhone } from 'utilities/validatePhone'
import * as yup from 'yup'

// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
yup.addMethod(yup.string, 'validatePhone', validatePhone)

export const defaultInitialValues: AuthFormValues = {
  type: AuthMethod.PHONE,
  phone: {
    value: '',
    countryCode: '',
    dialCode: '',
  },
}

export const useForm = (
  initialValues: Partial<AuthFormValues>,
  onSubmit: (
    values: AuthFormValues,
    actions: FormikHelpers<AuthFormValues>
  ) => void | Promise<unknown>
) => {
  const { t } = useTranslation('returnPrintForm')

  const validationSchema = yup.object().shape({
    phone: yup.object().shape({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call
      value: yup
        .string()
        .required(t('phone.required'))
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .validatePhone(t('phone.incorrect')),
      countryCode: yup.string().required(t('phone.required')),
      dialCode: yup.string(),
    }),
  })

  return useFormik<AuthFormValues>({
    initialValues: {
      ...defaultInitialValues,
      ...initialValues,
    },
    onSubmit,
    validationSchema,
    validateOnMount: false,
    validateOnChange: false,
    validateOnBlur: true,
  })
}
