import { Box } from 'components/ui/Box'
import { Flex } from 'components/ui/Flex'
import { Paper } from 'components/ui/Paper'
import { transparentize } from 'polished'
import styled, { css } from 'styled-components'
import { pageWrapper } from 'styles/mixins/page'
import { AnyColor } from 'styles/theme/palette'

export const MainScreen = styled.div`
  ${pageWrapper(true, 90, 0)}
  background-color: ${({ theme }) => theme.palette.primary.MAIN};
  padding: 0 16px;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
  z-index: 2;

  @media only screen and (max-width: 1320px) {
    margin-bottom: 0;
  }

  h1 {
    @media only screen and (max-width: 948px) {
      font-size: 22px;
    }
  }
`

export const Background = styled.div`
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  img {
    background-color: ${({ theme }) => theme.palette.primary.MAIN};
  }
  &:before {
    z-index: 2;
    content: '';
    position: absolute;
    pointer-events: none;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: ${({ theme }) => transparentize(0.4, theme.palette.primary.MAIN)};
  }
`

export const FormWrapper = styled.div`
  @media screen and (max-width: 1130px) {
    // height: 375px;
  }
`

export const StyledFlex = styled(Flex)`
  margin-bottom: 24px;
`

export const TitleTypographyWrapper = styled.div`
  h1 {
    white-space: pre-wrap;
    line-height: 1.2;
  }
`

export const SeoContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  line-height: 24px;
  column-gap: 67px;

  > div {
    &:nth-child(2) {
      max-width: 555px;
      @media screen and (max-width: 800px) {
        max-width: max-content;
      }
    }
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;
    row-gap: 16px;
  }
`

export const Bold = styled.span`
  font-weight: bold;
`

export const TextContent = styled(Box)`
  max-width: 460px;
  @media screen and (max-width: 800px) {
    max-width: max-content;
  }
`

export const PaperCard = styled(Paper)`
  padding: 24px;
  @media screen and (max-width: 800px) {
    padding: 16px;
  }
`

export const List = styled.ul<{ decimal?: boolean }>`
  list-style-type: none;
  color: #212121;
  li {
    ${({ decimal = false, theme }) =>
      decimal
        ? css`
            &:before {
              margin-right: 4px;
              font-size: 14px;
              font-family: 'Open Sans', sans-serif;
              letter-spacing: -0.4px;
              content: attr(data-index);
            }
          `
        : css`
            position: relative;
            padding-bottom: 8px;
            margin-top: 8px;
            &:before {
              content: '';
              width: 100%;
              height: 1px;
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
              background-color: ${theme.palette.core.GRAY};
            }

            &:last-child {
              &:before {
                display: none;
              }
            }
          `}
  }
`

export const StyledLink = styled.a`
  color: ${({ theme }) => theme.palette.core.BLUE};
  a {
    color: ${({ theme }) => theme.palette.core.BLUE};
  }
`

export const BlockWrapper = styled.div<{ bg?: AnyColor }>`
  padding: 40px 0;
  background-color: ${({ bg, theme }) => bg || theme.palette.core.WHITE};

  button {
    width: 100%;
  }

  @media screen and (max-width: 1180px) {
    padding: 32px 0;
    h5 {
      font-size: 22px;
    }
  }
`
