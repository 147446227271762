import baseAxios from 'api/axios/baseAxios'
import { URLS } from 'api/urls'
import { AuthFormValues, AuthMethod } from 'interfaces/Auth'
import { Phone } from 'interfaces/PassengerData'
import { removeSpaces } from 'utilities/removeSpaces'

export const providerMapping = {
  [AuthMethod.PHONE]: 'sms',
}

export const formatPhoneNumber = ({ value, dialCode }: Phone) =>
  removeSpaces(value.slice(dialCode?.length, value.length))

export const sendOTP = async (values: AuthFormValues) => {
  const formattedValue = formatPhoneNumber(values.phone)

  await baseAxios.get(URLS.getOTPCode, {
    params: {
      provider: providerMapping[values.type],
      [values.type]: formattedValue,
    },
  })
}
