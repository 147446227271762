import { cancelBooking } from 'api/requests/cancelBooking'
import { getBooking } from 'api/requests/getBooking'
import { Box } from 'components/ui/Box'
import { Flex } from 'components/ui/Flex'
import { Icon } from 'components/ui/Icon'
import { Typography } from 'components/ui/Typography'
import { LOCAL_STORAGE_KEYS } from 'core/constants'
import { compareAsc, parseISO } from 'date-fns'
import {
  Booking,
  BookingState,
  BookingTariffSegment,
  UnfinishedBooking as IUnfinishedBooking,
} from 'interfaces/Booking'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { FC, useEffect, useState } from 'react'
import { useToggle } from 'react-use'
import { useTheme } from 'styled-components'
import { getLocalStorageItem, setLocalStorageItem } from 'utilities/localStorage'

import { ButtonWrapperFlex, IconWrapper, StyledButton, Wrapper } from './styles'

export const UnfinishedBooking: FC = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  const router = useRouter()
  const [booking, setBooking] = useState<Booking | null>(null)
  const [isLoading, toggleLoading] = useToggle(false)
  const [cancellationBookingInProgress, toggleCancellationBookingInProgress] = useToggle(false)

  const closeUnfinishedBooking = async (withApiRequest = true) => {
    toggleCancellationBookingInProgress(true)
    try {
      if (withApiRequest) await cancelBooking(booking!.bookingNumber)
    } finally {
      setBooking(null)
      setLocalStorageItem(LOCAL_STORAGE_KEYS.UNFINISHED_BOOKING, null)
      toggleCancellationBookingInProgress(false)
    }
  }

  const goToUnfinishedBooking = () => {
    toggleLoading(true)
    const bookingUrl = `/booking/${booking?.bookingNumber || ''}/passengers`
    void router.push(bookingUrl)
  }

  const isExpired = (expires: string) => {
    const expiresDate = parseISO(expires)
    return Boolean(compareAsc(new Date(), expiresDate) + 1)
  }

  const fetchBooking = async (id: string) => {
    const payload = await getBooking(id)
    if (!payload || payload.status === BookingState.Canceled) return
    setBooking(payload)
  }

  useEffect(() => {
    const unfinishedBooking = getLocalStorageItem<IUnfinishedBooking>(
      LOCAL_STORAGE_KEYS.UNFINISHED_BOOKING
    )
    if (!unfinishedBooking) return () => {}
    if (isExpired(unfinishedBooking.expires)) {
      void closeUnfinishedBooking(false)
      return () => {}
    }
    void fetchBooking(unfinishedBooking.id)
    return () => {}
  }, [])

  if (!booking) return null

  const getDestinations = () => {
    const outboundTariffSegment = booking.outboundBookingTariffSegments[0]
    const inboundTariffSegment = booking.inboundBookingTariffSegments[0]

    const getDestinationBySegment = (segment: BookingTariffSegment) => {
      if (!segment) return ''
      return `${segment.journeySegment.departureStation.name} - ${segment.journeySegment.arrivalStation.name}`
    }

    const destinations = [
      getDestinationBySegment(outboundTariffSegment),
      getDestinationBySegment(inboundTariffSegment),
    ]

    return destinations.filter(d => Boolean(d)).join(', ')
  }

  return (
    <Wrapper>
      <Box padding={16}>
        <Typography
          typography={theme.typography.variants.title.H2}
          color={theme.palette.secondary.MAIN}
        >
          {t('unfinishedBooking:unfinishedPayment')}
        </Typography>
        <Box marginTop={8}>
          <Typography>{getDestinations()}</Typography>
        </Box>
        <Box>
          <Typography> {t('unfinishedBooking:continue')}</Typography>
        </Box>
        <Box marginTop={24}>
          <ButtonWrapperFlex container>
            <Flex item grow={1}>
              <StyledButton
                isLoading={cancellationBookingInProgress}
                onClick={() => closeUnfinishedBooking()}
                type='button'
                width={162}
                fill='outlined'
                variant='flat'
                hoverColor={theme.palette.secondary.MAIN}
              >
                {t('core:close')}
              </StyledButton>
            </Flex>
            <Flex item grow={1}>
              <StyledButton
                onClick={goToUnfinishedBooking}
                isLoading={isLoading}
                type='button'
                width={162}
                fill='outlined'
                variant='flat'
                hoverColor={theme.palette.primary.MAIN}
              >
                {t('core:continue')}
              </StyledButton>
            </Flex>
          </ButtonWrapperFlex>
        </Box>
      </Box>
      <IconWrapper>
        <Icon
          icon={theme.icons.stepper.PassengerData}
          width={105}
          height={82}
          color={theme.palette.secondary.MAIN}
        />
      </IconWrapper>
    </Wrapper>
  )
}
