import { getStations } from 'api/requests/getStations'
import { AuthPurpose } from 'interfaces/Auth'
import { GetServerSidePropsContext } from 'next'
import { initialState as initialStationsState } from 'store/features/stations'
import { isAuthenticated } from 'utilities/isAuthenticated'

export { HomePage as default } from 'components/pages/Home'

export const getServerSideProps = async ({ locale, req, query }: GetServerSidePropsContext) => {
  const stations = await getStations(locale)
  const authorization = await isAuthenticated(req)
  const keys = Object.keys(query)

  if (
    (keys.includes(AuthPurpose.REFUND) || keys.includes(AuthPurpose.PRINT)) &&
    authorization.isAuthenticated
  ) {
    return {
      redirect: {
        destination: '/booking/history',
        permanent: true,
      },
    }
  }

  return {
    props: {
      preloadedState: {
        stations: {
          ...initialStationsState,
          entities: stations,
        },
        authorization,
      },
    },
  }
}
