import { Button } from 'components/ui/Button'
import { Flex } from 'components/ui/Flex'
import styled from 'styled-components'

export const Wrapper = styled.div`
  right: 7vw;
  z-index: 10;
  border-radius: 8px;
  position: fixed;
  bottom: 18px;
  width: 365px;
  overflow: hidden;
  box-shadow: 0px 0px 10px rgba(228, 60, 74, 0.14), 0px 0px 14px rgba(228, 60, 74, 0.12);
  background: ${({ theme }) => theme.palette.core.WHITE};
  z-index: 1000;

  @media screen and (min-width: 1440px) {
    right: calc((100vw - 1140px) / 2);
  }
  @media screen and (max-width: 860px) {
    bottom: 18px;
  }
  @media screen and (max-width: 576px) {
    right: 16px;
    width: calc(100vw - 16px * 2);
  }
`

export const IconWrapper = styled.div`
  position: absolute;
  top: -5px;
  right: -15px;
  opacity: 0.1;
`

export const StyledButton = styled(Button)`
  @media screen and (max-width: 576px) {
    width: 100%;
  }
`

export const ButtonWrapperFlex = styled(Flex)`
  @media screen and (max-width: 576px) {
    flex-direction: column;
    row-gap: 8px;
  }
`
