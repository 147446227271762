import { pushEvent } from 'analytics/push'
import { AnalyticsEventType } from 'interfaces/GoogleAnalytics'

export enum AuthStatus {
  Success = 'success',
  Failure = 'error',
}

export const authEvent = (method: string, action: AuthStatus) => {
  pushEvent({
    event: AnalyticsEventType.AUTH,
    eventProperties: {
      eventCategory: 'auth',
      eventAction: action,
      eventLabel: method,
    },
  })
}
