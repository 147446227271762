import { pushEvent } from 'analytics/push'
import { AnalyticsEventType } from 'interfaces/GoogleAnalytics'

export const pageLoadEvent = (pageName: string, platformLanguage: string) => {
  const platform = navigator?.userAgentData?.platform || navigator?.platform || 'unknown'

  pushEvent({
    event: AnalyticsEventType.PAGELOAD,
    eventProperties: {
      pageName,
      platform,
      platformLanguage,
    },
  })
}
