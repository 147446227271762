import { FormVariant } from 'interfaces/FormVariant'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { useUpdateEffect } from 'react-use'
import { updateQuery } from 'utilities/updateQuery'

export const useFormVariant = (): FormVariant => {
  const router = useRouter()

  const getFormVariant = (): FormVariant => {
    const { query } = router
    if ('refund' in query) return 'refund'
    if ('print' in query) return 'print'
    return 'search'
  }

  const getFormattedString = (params: URLSearchParams): string => params.toString().replace('=', '')

  const [formVariant, setStateFormVariant] = useState<FormVariant>(getFormVariant())

  const appendParams = (variant: FormVariant) => {
    const params = new URLSearchParams()
    if (variant === 'refund') params.append('refund', '')
    if (variant === 'print') params.append('print', '')
    return params
  }

  const setFormVariant = (variant: FormVariant): void => {
    const params = appendParams(variant)
    const paramsLength = Array.from(params).length
    if (paramsLength) void updateQuery(getFormattedString(params))
    void setStateFormVariant(variant)
  }

  useUpdateEffect(() => {
    void setFormVariant(getFormVariant())
  }, [router])

  return formVariant
}
