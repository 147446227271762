import { Flex } from 'components/ui/Flex'
import styled, { css } from 'styled-components'

export const Wrapper = styled.div``

export const FormSelectorFlex = styled(Flex)`
  @media only screen and (max-width: 630px) {
    margin-left: auto;
  }
`

export const Separator = styled.div`
  width: 1px;
  height: 13px;
  margin: 0 21px;
  background-color: ${({ theme }) => theme.palette.core.VERY_LIGHT_GRAY};

  @media only screen and (max-width: 365px) {
    height: 10px;
    margin: 0 14px;
  }
`

interface StyledLinkProps {
  selected: boolean
}

export const StyledLink = styled.a<StyledLinkProps>`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  transition: all 0.2s ease;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  opacity: 0.7;
  color: ${({ theme }) => theme.palette.core.WHITE};
  &:before {
    content: attr(data-title) ' ';
    font-weight: bold;
    visibility: hidden;
    overflow: hidden;
    user-select: none;
    pointer-events: none;
    height: 0;
  }

  &:hover {
    opacity: 0.9;
  }

  ${({ selected }) =>
    selected &&
    css`
      font-weight: 700;
      opacity: 1;
    `}

  @media only screen and (max-width: 630px) {
    text-align: left;
    line-height: 18px;
  }

  @media only screen and (max-width: 365px) {
    font-size: 13px;
  }
`
