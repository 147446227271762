import { Button } from 'components/ui/Button'
import { Flex } from 'components/ui/Flex'
import styled from 'styled-components'

interface Props {
  phoneWidth: number
}

export const Wrapper = styled.div<Props>`
  .phone {
    &__container {
      display: flex;
      flex-direction: row-reverse;
    }

    &__form-control {
      border-radius: 0 12px 12px 0;
      margin: 0;
      border-color: ${({ theme }) => theme.palette.core.LIGHT_GRAY};
      width: 100%;
      height: 48px;
      padding: 14px 16px;

      &::placeholder {
        color: ${({ theme }) => theme.palette.core.LIGHT_GRAY};
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.04em;
      }
    }

    &__flag-dropdown {
      width: 53px;
      position: relative;
      border-radius: 12px 0 0 12px;
      border-right: 0;
      background-color: transparent;
      border-color: ${({ theme }) => theme.palette.core.LIGHT_GRAY};

      .selected-flag {
        width: 53px;
        padding: 0 0 0 18px;

        .arrow {
          border-top-color: ${({ theme }) => theme.palette.core.DEEP_GRAY};

          &.up {
            border-bottom-color: ${({ theme }) => theme.palette.core.DEEP_GRAY};
          }
        }
      }
    }

    &__country-list {
      width: ${({ phoneWidth }) => `${phoneWidth}px`};
      max-height: 220px;
      margin-top: 4px;
      padding-top: 17px;
      padding-bottom: 11px;
      color: ${({ theme }) => theme.palette.core.WHITE};
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
      border-radius: 0 0 12px 12px;

      .country {
        padding: 8px 16px;
        transition: all 0.2s ease;

        &.highlight {
          background-color: ${({ theme }) => theme.palette.primary.MAIN};

          &:hover {
            background-color: ${({ theme }) => theme.palette.primary.MAIN};
          }

          .country-name,
          .dial-code {
            color: ${({ theme }) => theme.palette.core.WHITE};
          }
        }

        &:hover {
          background-color: ${({ theme }) => theme.palette.core.VERY_LIGHT_GRAY};
        }
      }

      .country-name {
        color: ${({ theme }) => theme.palette.core.BLACK};
      }

      .dial-code {
        color: ${({ theme }) => theme.palette.core.DEEP_GRAY};
      }
    }
  }

  .react-tel-input {
    .invalid-number {
      &.form-control,
      &.flag-dropdown {
        border-color: ${({ theme }) => theme.palette.secondary.MAIN};
      }

      &.form-control {
        background-color: transparent;
      }
    }

    .open {
      &.flag-dropdown,
      &.selected-flag {
        border-radius: 12px 0 0 12px;
        background: transparent;
      }
    }

    .selected-flag {
      &:focus,
      &:hover {
        background-color: transparent;
      }
    }
  }
`

export const FlexInputsWrapper = styled(Flex)`
  flex-wrap: nowrap;
  flex-grow: 1;
  margin-right: 24px;
  z-index: 2;

  @media screen and (max-width: 992px) {
    flex-wrap: wrap;
    margin-bottom: 24px;
    margin-right: 0;
  }
`

export const UserDataInputWrapper = styled.div`
  flex-grow: 1;

  @media screen and (max-width: 992px) {
    flex-grow: 1;
    flex-basis: auto;
  }
`

export const StyledFormWrapper = styled.form`
  display: flex;
  align-items: flex-start;

  @media screen and (max-width: 992px) {
    align-items: normal;
    flex-direction: column;
  }
`

export const StyledButton = styled(Button)`
  width: 320px;

  @media screen and (max-width: 992px) {
    width: 100%;
  }
`
