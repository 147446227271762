import { Phone } from 'interfaces/PassengerData'

export enum AuthPurpose {
  PRINT = 'print',
  REFUND = 'refund',
}

export enum AuthMethod {
  PHONE = 'phone',
}

export interface AuthFormValues {
  type: AuthMethod
  phone: Phone
}

export interface TokensResponse {
  token: string
  refreshKey: string
}
